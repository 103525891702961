<template>
  <div>
    <CyDetails
      :item-id="configRepositoryCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.showDeleteDialog"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="isLoading"
      :saving="isSaving"
      :deleting="isDeleting"
      :is-read-only="!canUpdateConfigRepository">
      <template slot="details_form">
        <CyNotification
          theme="error"
          :content="errors"/>

        <v-text-field
          v-model="$v.formData.name.$model"
          :label="$t('forms.fieldName')"
          :error-messages="nameErrors"
          :disabled="!canUpdateConfigRepository"
          :readonly="!canUpdateConfigRepository"
          required
          data-cy="cr-name-input"
          class="required-field"
          @blur="$v.formData.name.$touch()"/>

        <CySearchBox
          v-model="$v.formData.url.$model"
          :error-messages="urlErrors"
          :disabled="isUrlReadOnly"
          :readonly="isUrlReadOnly"
          :hint="hints.urlGithub"
          :persistent-hint="!$isCreationRoute"
          required
          clearable
          data-cy="cr-url-input"
          class="required-field has-copy-btn">
          <v-icon
            v-if="!$isCreationRoute && canUpdateConfigRepository"
            slot="append-outer"
            color="darkgrey"
            @click="$toggle.isUrlLocked">
            {{ isUrlLocked ? 'lock' : 'lock_open' }}
          </v-icon>
          <span slot="label">{{ $t('untranslated.URL') }}</span>
          <CyCopyBtn
            v-if="!$v.formData.url.$invalid"
            slot="append"
            :copy-value="formData.url"/>
        </CySearchBox>

        <CyCredentials
          v-if="showCredentials"
          v-model="$v.formData.credential_canonical.$model"
          clearable
          :class="['mb-3', {
            'mt-5': !$isCreationRoute,
            'required-field': isPrivate,
          }]"
          :disabled="!canUpdateConfigRepository"
          :readonly="!canUpdateConfigRepository"
          :error-messages="credentialCanonicalErrors"
          :hint="isPrivate ? $t('credentialRequiredForBranches') : null"
          :items="filteredCredentials"
          :label="$t('Credential')"
          :required="isPrivate"
          @blur="$v.formData.credential_canonical.$touch()"/>

        <v-row
          v-if="showBranches"
          no-gutters>
          <v-select
            v-model="$v.formData.branch.$model"
            :disabled="!canFetchBranches || !canUpdateConfigRepository"
            :readonly="!canUpdateConfigRepository"
            :items="branches"
            :menu-props="{ offsetY: true }"
            :label="$t('forms.fieldBranch')"
            :hint="hints.fetchBranches"
            persistent-hint
            hide-selected
            required
            data-cy="branch-select"
            class="required-field flex-1-1-0">
            <template #selection="{ item }">
              <div class="black--text">
                {{ item }}
              </div>
            </template>

            <template #item="{ item }">
              <v-list-item-content data-cy="branch-list-item">
                <v-list-item-title>
                  {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

          <CyTooltip
            v-if="canUpdateConfigRepository"
            right
            transition="slide-x-transition"
            :disabled="!canFetchBranches">
            <template #activator="{ on: refreshTooltip }">
              <span
                class="align-self-center ml-5"
                v-on="refreshTooltip">
                <CyButton
                  :readonly="!canFetchBranches"
                  :disabled="!canFetchBranches"
                  :loading="isFetchingBranches"
                  icon="refresh"
                  variant="secondary"
                  icon-only
                  sm
                  @click="getBranches"/>
              </span>
            </template>
            <span>
              {{ isFetchingBranches ? $t('refreshingBranches') : $t('refreshBranches') }}
            </span>
          </CyTooltip>
        </v-row>

        <v-switch
          v-if="!_.isEmpty(branches)"
          v-model="$v.formData.default.$model"
          :label="$t('untranslated.default')"
          :hint="$t('fieldSwitchHint')"
          :disabled="!canUpdateConfigRepository"
          :readonly="!canUpdateConfigRepository"
          persistent-hint
          required
          color="accent"
          class="required-field default-switch"/>
      </template>

      <!-- Related projects -->
      <template slot="details_formAside">
        <div
          v-if="!$isCreationRoute"
          class="stacks__container">
          <p v-html="$sanitizeHtml($tc('projectsUsingConfigRepository', projects.length, { projectsCount: projects.length }))"/>
          <CyTagList
            small
            class="stacks__list"
            :tags="projects">
            <template #tag="{ tag }">
              <CyTag
                class="clickable"
                variant="primary"
                data-cy="project-tag"
                element-type="button"
                @click.stop="$router.push({
                  name: 'projectConfiguration',
                  params: { projectCanonical: tag.canonical },
                })">
                {{ tag.name }}
              </CyTag>
            </template>
          </CyTagList>
        </div>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-func="onDeleteConfirm"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ formData.name }}</h3>
      <p class="url">
        {{ formData.url }}
      </p>
      <p class="ma-0">
        {{ $t('confirmDeleteRepository') }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { constructBreadcrumb, checksPass, anyChecksPass } from '@/utils/helpers'
import REGEX from '@/utils/config/regex'
import CyCredentials from '@/components/credentials'
import CyDetails from '@/components/details'
import CyCopyBtn from '@/components/copy-btn'
import CyTagList from '@/components/tag-list'
import CySearchBox from '@/components/search-box'

const PUBLIC = 'PUBLIC'
const PRIVATE = 'PRIVATE'

export default {
  name: 'CyPageConfigRepository',
  components: {
    CyCredentials,
    CyDetails,
    CyCopyBtn,
    CyTagList,
    CySearchBox,
  },
  breadcrumb () {
    const header = this.$isCreationRoute
      ? this.$t('addConfigRepository')
      : this.configRepository?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.configRepositories'),
        name: 'configRepositories',
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const { $isCreationRoute, configRepository } = this
    const title = $isCreationRoute
      ? this.$t('addConfigRepository')
      : configRepository?.name

    if ($isCreationRoute) return { title }
    if (!configRepository) return {}

    const { created_at: createdAt, updated_at: updatedAt, default: isDefault } = configRepository
    const tag = isDefault ? this.$t('Default') : null

    return { title, tag, createdAt, updatedAt }
  },
  props: {
    configRepositoryCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formData: {
      name: '',
      url: '',
      branch: '',
      credential_canonical: null,
      default: false,
    },
    hasFetchedBranchesFor: {},
    isDeleting: false,
    isFetchingBranches: false,
    isLoading: true,
    isSaving: false,
    isUrlLocked: true,
    showDeleteDialog: false,
  }),
  validations: {
    formData: {
      name: { required },
      url: {
        required,
        isValidGitUrl: (url) => REGEX.GIT.test(url),
      },
      branch: { required },
      credential_canonical: {
        required: requiredIf(function () {
          return this.isPrivate
        }),
      },
      default: {},
    },
  },
  computed: {
    ...mapState('organization', {
      hasCredentials: (state) => !_.isEmpty(state.available.credentials),
      projects: (state) => state.available.projects,
    }),
    ...mapState('organization/configRepository', {
      branches: (state) => _.$get(state, 'branches', []),
      configRepositoryErrors: (state) => state.errors,
    }),
    ...mapGetters('organization', [
      'getCredentialsByType',
    ]),
    ...mapGetters('organization/configRepository', [
      'configRepository',
      'hasBranchesError',
      'hasBranches',
    ]),
    canUpdateConfigRepository () {
      return this.$isCreationRoute
        ? this.$cycloid.permissions.canDisplay('CreateConfigRepository')
        : this.$cycloid.permissions.canDisplay('UpdateConfigRepository', this.configRepositoryCanonical)
    },
    showCredentials () {
      return this.configRepository?.credential_canonical || (this.isPrivate && !this.$v.formData.url.$invalid)
    },
    isPrivate () {
      return _.isEqual(_.$get(this.hasFetchedBranchesFor, this.formData.url), PRIVATE)
    },
    errors () {
      const { branches, configRepository } = this.configRepositoryErrors

      return _.filter([
        ...branches,
        ...configRepository,
      ], ({ code }) => code !== 'Required')
    },
    hasErrors () {
      return !_.isEmpty(this.errors)
    },
    canCancel () {
      return this.$hasDataChanged('formData')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.formData.$invalid
        : !this.$v.formData.$invalid && this.canCancel
    },
    showBranches () {
      return _.isString(this.hasFetchedBranchesFor[this.formData.url])
    },
    canFetchBranches () {
      const checks = {
        hasNoErrors: !this.hasErrors,
        hasValidURL: !this.$v.formData.url.$invalid,
        hasValidCred: !this.$v.formData.credential_canonical.$invalid,
        notFetchingBranches: !this.isFetchingBranches,
      }
      return checksPass(checks)
    },
    isUrlReadOnly () {
      return !this.$isCreationRoute && this.isUrlLocked
    },
    hints () {
      const { isUrlReadOnly, isFetchingBranches, hasBranches, isPrivate } = this
      return {
        urlGithub:
          isUrlReadOnly
            ? this.$t('fieldGitHintReadonly')
            : this.$t('fieldGitHint'),
        fetchBranches: (() => {
          if (isFetchingBranches) return this.$t('fetchingBranches')
          if (hasBranches) return this.$t('fetchBranchesSuccess')
          return isPrivate
            ? this.$t('fetchBranchesFieldsMissing.private')
            : this.$t('fetchBranchesFieldsMissing.public')
        })(),
      }
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    urlErrors () {
      const errors = []
      const { $dirty, required, isValidGitUrl } = this.$v.formData.url
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isValidGitUrl) errors.push(this.$t('forms.fieldInvalidGitUrl'))
      return errors
    },
    credentialCanonicalErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.credential_canonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (this.hasBranchesError) errors.push(this.$t('fetchBranchesError'))
      return errors
    },
    crCanonical () {
      const { configRepositoryCanonical, formData: { name: configRepositoryName } } = this
      return this.$isCreationRoute ? this.$getSlug(configRepositoryName) : configRepositoryCanonical
    },
    filteredCredentials () {
      if (!this.hasCredentials) return []
      return this.formData.url?.startsWith('https')
        ? this.getCredentialsByType('basic_auth')
        : this.getCredentialsByType('ssh')
    },
    branchesWatcher () {
      return [
        this.formData.url || '',
        this.formData.credential_canonical || '',
      ]
    },
  },
  watch: {
    branchesWatcher: {
      async handler () {
        this.SET_BRANCHES(null)
        await this.getBranches()
      },
      deep: true,
    },
    'formData.branch' () {
      this.CLEAR_CR_ERRORS()
    },
    'formData.credential_canonical' (newValue) {
      if (_.isEmpty(newValue)) this.$v.formData.branch.$model = ''
    },
    'formData.url' (newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== '') {
        this.$set(this.formData, 'credential_canonical', '')
        this.$v.formData.credential_canonical.$reset()
        this.CLEAR_CR_ERRORS()
      }
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials' })

    if (!this.$isCreationRoute) {
      await this.GET_CONFIG_REPOSITORY({ configRepositoryCanonical: this.crCanonical })
      this.setItemAndFormData()
      await this.getBranches()
      if (this.configRepository?.id) await this.FETCH_AVAILABLE({ keyPath: 'projects', extraParams: [{ configRepositoryId: this.configRepository?.id }] })
    }

    this.$toggle.isLoading(false)
  },
  destroyed () {
    this.RESET_CR_STATE()
    this.RESET_ORG_STATE('available.projects')
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/configRepository', [
      'CREATE_CONFIG_REPOSITORY',
      'DELETE_CONFIG_REPOSITORY',
      'GET_BRANCHES',
      'GET_CONFIG_REPOSITORY',
      'UPDATE_CONFIG_REPOSITORY',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    ...mapMutations('organization/configRepository', [
      'CLEAR_CR_ERRORS',
      'RESET_CR_STATE',
      'SET_BRANCHES',
    ]),
    async getBranches () {
      if (this.$v.formData.url.$invalid) return

      this.$toggle.isFetchingBranches(true)
      const { credential_canonical: credentialCanonical, url } = this.formData

      if (!_.has(this.hasFetchedBranchesFor, url)) this.$set(this.hasFetchedBranchesFor, url, PUBLIC)
      await this.GET_BRANCHES({ credentialCanonical, url })

      const markAsPrivate = anyChecksPass({
        noBranchesNoCred: !this.hasBranches && _.$isEmpty(credentialCanonical),
        hasBranchesAndCred: this.hasBranches && !_.$isEmpty(credentialCanonical),
      })
      if (markAsPrivate) this.$set(this.hasFetchedBranchesFor, url, PRIVATE)
      if (this.$isCreationRoute && this.branches.length === 1) this.formData.branch = this.branches[0]

      this.$toggle.isFetchingBranches(false)
    },
    onCancel () {
      this.$toggle.isUrlLocked(true)
      this.CLEAR_CR_ERRORS()
      this.$resetData('formData')
      this.$v.$reset()
    },
    async onSave () {
      if (this.isSaving) return

      this.$toggle.isSaving(true)

      const { crCanonical: configRepositoryCanonical, $router } = this
      const configRepository = { ...this.formData, canonical: configRepositoryCanonical }

      this.$isCreationRoute
        ? await this.CREATE_CONFIG_REPOSITORY({ configRepository, $router })
        : await this.UPDATE_CONFIG_REPOSITORY({ configRepository })

      this.$toggle.isSaving(false)

      if (this.hasErrors) return

      this.setItemAndFormData()
    },
    async onDeleteConfirm () {
      const { formData: configRepository, $router } = this

      this.$toggle.isDeleting(true)
      await this.DELETE_CONFIG_REPOSITORY({ configRepository, $router })
      this.$toggle.showDeleteDialog(false)
      this.$toggle.isDeleting(false)
    },
    setItemAndFormData () {
      if (!this.configRepository) return

      this.$set(this, 'formData', _.cloneDeep(this.configRepository))
      this.$setOriginalData()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.configRepository',
        addConfigRepository: 'Add config repository',
        confirmDeleteHeader: 'Delete config repository',
        confirmDeleteRepository: 'Please note, that you will need to delete the associated git repository, manually.',
        confirmDeleteSentence: 'Are you sure that you want to delete this config repository?',
        credentialRequiredForBranches: 'This field is required to fetch branch information.',
        fetchBranchesError: 'Valid credentials must be provided in order to fetch the branches.',
        fetchBranchesFieldsMissing: {
          public: 'Please supply a valid git URL before selecting a branch.',
          private: 'Please supply a valid git URL and credentials before selecting a branch.',
        },
        fetchBranchesSuccess: 'Branches fetched.',
        fetchingBranches: 'Fetching repository branches...',
        fieldGitHint: 'This field should contain a valid Git URL',
        fieldGitHintReadonly: 'This field is readonly because changing it could break something. If you still need to edit it, please click on the lock',
        fieldSwitchHint: 'If enabled, this repository will be set as default option on project creation.',
        projectsUsingConfigRepository: '<strong>{projectsCount}</strong> project is using this config repository: | <strong>{projectsCount}</strong> projects are using this config repository:',
        refreshBranches: 'Refresh branches',
        refreshingBranches: 'Refreshing branches...',
      },
      es: {
        title: '@:routes.configRepository',
        addConfigRepository: 'Añadir repositorios de configuración',
        confirmDeleteHeader: 'Borrar repositorios de configuración',
        confirmDeleteRepository: 'Tenga en cuenta que deberá eliminar el repositorio git asociado manualmente.',
        confirmDeleteSentence: '¿Estás seguro de querer borrar este repositorios de configuración?',
        credentialRequiredForBranches: 'Este campo es obligatorio para obtener la información de la sucursal.',
        fetchBranchesError: 'No se pueden recuperar ramas: la URL git o la credencial no son válidas.',
        fetchBranchesFieldsMissing: {
          public: 'Por favor, seleccione una URL git antes de seleccionar una rama.',
          private: 'Por favor, seleccione una URL git y credenciales validas antes de seleccionar una rama.',
        },
        fetchBranchesSuccess: 'Ramas obtenidas.',
        fetchingBranches: 'Obteniendo ramas del repositorio...',
        fieldGitHint: 'Este campo debe contener una URL Git válida',
        fieldGitHintReadonly: 'Este campo es de solo lectura porque cambiarlo podría romper algo. Si aún necesita editarlo, haga clic en el candado',
        fieldSwitchHint: 'Si habilitado, este repositorio se establecerá como opción predeterminada en la creación del proyecto.',
        projectsUsingConfigRepository: 'Tiene <strong>{projectsCount}</strong> proyecto usando este repositorio de configuración: | Tiene <strong>{projectsCount}</strong> proyectos que utilizan este repositorio de configuración:',
        refreshBranches: 'Actualizar ramas',
        refreshingBranches: 'Actualizando ramas...',
      },
      fr: {
        title: '@:routes.configRepository',
        addConfigRepository: 'Ajouter une source de configuration',
        confirmDeleteHeader: 'Supprimer les sources de configuration',
        confirmDeleteRepository: 'Veuillez noter que vous devrez supprimer manuellement le dépot git associé.',
        confirmDeleteSentence: 'Êtes-vous sûr de vouloir supprimer ces sources de configuration ?',
        credentialRequiredForBranches: 'Ce champ est obligatoire pour récupérer les informations de la branche.',
        fetchBranchesError: `Impossible de récupérer les branches: les informations d'identification ou l'URL du dépot ne sont pas valides.`,
        fetchBranchesFieldsMissing: {
          public: 'Veuillez sélectionner une URL git valide de sélectionner une branche.',
          private: `Veuillez sélectionner une URL git valide et des informations d'identification avant de sélectionner une branche.`,
        },
        fetchBranchesSuccess: 'Des branches ont été récupérée.',
        fetchingBranches: 'Récupération des branches de la source du catalogue...',
        fieldGitHint: 'Ce champ doit contenir une URL Git valide',
        fieldGitHintReadonly: `Ce champ est en lecture seule car le changer pourrait casser quelque chose. Si vous avez besoin de l'éditer, veuillez cliquer sur le cadenas`,
        fieldSwitchHint: `Si elle est activée, cette source sera définie comme option par défaut lors de la création d'un projet.`,
        projectsUsingConfigRepository: 'Possède <strong> {projectsCount} </strong> projet utilisant cette source de configuration: | Possède <strong> {projectsCount} </strong> projets utilisant cette source de configuration:',
        refreshBranches: 'Actualiser les branches',
        refreshingBranches: 'Actualisation des branches...',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.default-switch ::v-deep {
  .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .v-label {
    flex: 0;
    margin-right: 12px;
  }
}

.has-copy-btn ::v-deep .v-label {
  height: auto;
}

.url {
  color: get-color("grey", "dark-2");
}

.card-title {
  display: flex;
  flex-direction: column;
}

.v-toolbar__content {
  line-height: 62px;
}
</style>
