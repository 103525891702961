<template>
  <component
    v-ripple="isClickable"
    :class="['tag', {
      'tag--small': small,
      'tag--tall': tall,
      'tag--selected': isSelected,
      'tag--coming-soon': comingSoon,
    }, variantClass]"
    v-on="$listeners"
    :is="_elementType">
    <v-icon
      v-if="iconBefore"
      class="tag__icon tag__icon--before"
      :tabindex="!focusable ? -1 : undefined"
      @click.stop="$emit('click-icon-before')">
      {{ iconBefore }}
    </v-icon>
    <img
      v-if="imageBefore"
      :alt="label"
      class="tag__image"
      :src="imageBefore"
      :tabindex="!focusable ? -1 : undefined"
      @click="$emit('click-image-before')">
    <b v-if="label">{{ label }}:</b>
    <slot name="default"/>
    <v-icon
      v-if="iconAfter"
      class="tag__icon tag__icon--after"
      :tabindex="!focusable ? -1 : undefined"
      @click.stop="$emit('click-icon-after')">
      {{ iconAfter }}
    </v-icon>
  </component>
</template>

<script>
export const variants = ['primary', 'secondary', 'accent', 'error', 'warning', 'success', 'default']

export default {
  name: 'CyTag',
  props: {
    label: {
      type: String,
      default: null,
    },
    comingSoon: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    elementType: {
      type: String,
      default: 'span',
    },
    small: {
      type: Boolean,
      default: false,
    },
    tall: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      validator: (variant) => variants.includes(variant),
      default: 'secondary',
    },
    imageBefore: {
      type: String,
      default: null,
    },
    iconBefore: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _elementType () {
      return this.$attrs.href ? 'a' : this.elementType
    },
    isClickable () {
      return _.includes(['a', 'button'], this._elementType)
    },
    variantClass () {
      return `tag--${this.variant}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import "styles/variables/cytag";

.tag {
  display: inline-flex;
  align-items: center;
  padding: $tag-padding;
  border-radius: $tag-border-radius;
  font-family: $tag-font-family;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight;
  line-height: $tag-line-height;
  text-align: left;
  vertical-align: top;

  &--selected {
    background-color: get-color("secondary") !important;
    color: get-color("white") !important;
  }

  &__icon {
    font-size: $tag-icon-size;

    &--before {
      margin-right: $tag-icon-margin;
    }

    &--after {
      margin-left: $tag-icon-margin;
    }
  }

  &__image {
    width: $tag-icon-size;
    height: $tag-icon-size;
  }

  &--small {
    padding: $tag-padding-small;
    font-size: $tag-font-size-small;

    @extend .tag;

    &__icon {
      font-size: $tag-icon-size-small;
    }

    &__image {
      width: $tag-font-size-small;
      height: $tag-font-size-small;
    }
  }

  &--tall {
    padding: $tag-padding-tall;
    font-size: $tag-font-size-tall;

    @extend .tag;

    &__image {
      width: $tag-font-size-tall;
      height: $tag-font-size-tall;
    }
  }

  &--coming-soon {
    font-weight: 500;

    &::after {
      content: "...";
      padding-left: 1px;
      letter-spacing: -2px;
    }
  }

  @at-root a#{&}, button#{&} {
    transition: $tag-transition;
  }
}

@each $name, $variant in $tag-color-variants {
  .tag--#{$name} {
    background-color: map.get($variant, "background-color");
    color: map.get($variant, "text-color");

    > .tag__icon {
      color: map.get($variant, "text-color");
    }

    @at-root a#{&}, button#{&} {
      &:hover,
      &:focus {
        background-color: map.get($variant, "hover-background-color");
        color: map.get($variant, "hover-text-color");

        > .tag__icon {
          color: map.get($variant, "hover-text-color");
        }
      }
    }
  }
}
</style>
