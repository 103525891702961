<template>
  <div :class="['cy-details new-header', { 'sticky-footer__viewport': !hideFooter }]">
    <div class="cy-details__content">
      <v-progress-circular
        v-if="loading"
        indeterminate
        data-cy="loader"
        size="100"
        class="ma-auto"
        color="secondary"/>

      <template v-else>
        <section
          v-if="$slots['details_formTopFullWidth']"
          class="cy-details_section cy-details__section--full-width cy-details__section--top">
          <slot name="details_formTopFullWidth"/>
        </section>

        <div
          v-if="_.some([$slots['details_form'], $slots['details_formAside']])"
          class="cy-details__section cy-details__section-contained-wrapper">
          <section
            v-if="$slots['details_form']"
            class="cy-details__section cy-details__section--contained">
            <slot name="details_form"/>
          </section>

          <section
            v-if="$slots['details_formAside']"
            class="cy-details__section cy-details__section--contained">
            <slot name="details_formAside"/>
          </section>
        </div>

        <section
          v-if="$slots['details_formFullWidth']"
          class="cy-details__section cy-details__section--full-width">
          <slot name="details_formFullWidth"/>
        </section>

        <section
          v-if="$slots['details_secondaryContent']"
          class="cy-details__section">
          <slot name="details_secondaryContent"/>
        </section>

        <section
          v-if="$slots['details_secondaryContentFullWidth']"
          class="cy-details__section cy-details__section--full-width">
          <slot name="details_secondaryContentFullWidth"/>
        </section>
      </template>
    </div>

    <div
      v-if="!loading && !isReadOnly && !hideFooter"
      data-cy="actions-buttons"
      class="cy-details__actions sticky-footer__footer">
      <CyButton
        v-if="can().delete"
        key="cy-details__delete-btn"
        :loading="deleting"
        class="cy-details__button mr-auto"
        data-cy="delete-button"
        variant="tertiary"
        theme="error"
        icon="delete"
        @click.native="onDelete(true)">
        {{ deleteBtnText }}
      </CyButton>

      <slot name="details_actionBtns">
        <CyButton
          v-if="canCancel"
          key="cy-details__cancel-btn"
          class="cy-details__button"
          data-cy="cancel-button"
          variant="secondary"
          theme="primary"
          icon="close"
          @click.native="onCancel">
          {{ $t('forms.btnCancel') }}
        </CyButton>

        <CyButton
          v-if="can().save"
          key="cy-details__save-btn"
          :disabled="!canSave"
          :loading="saving"
          class="cy-details__button"
          data-cy="save-button"
          variant="primary"
          theme="success"
          icon="done"
          type="submit"
          @click.native="onSave">
          {{ saveBtnText }}
        </CyButton>
      </slot>
    </div>
  </div>
</template>

<script>
import i18n from '@/utils/plugins/i18n'

/**
 * Details wrapper to handle singular CRUD operations
 * - conditional buttons and content depending on create/update mode
 * - also provides space for secondary content, after the form slot
 *
 * REQUIRED
 * @prop {Boolean}  [canCancel]                     handles visibility for cancel button
 * @prop {Boolean}  [canSave]                       handles disabled attribute for save button
 * @prop {Boolean}  [loading]                       should be true when GET request is being sent to api; shows loader icon
 * @prop {Function} [onCancel]                      function executed when cancel button clicked
 * @prop {Function} [onSave]                        function executed when save button clicked
 * @prop {Boolean}  [saving]                        should be true when POST/PUT request is being sent to api; shows loader icon
 *
 * OPTIONAL
 * @prop {Boolean}  [deleteBtnText=forms.btnDelete] to override the delete button text from "delete"
 * @prop {Boolean}  [deleting]                      should be true when DELETE request is being sent to api; shows loader icon
 * @prop {Boolean}  [hideDelete=false]              handles visibility for delete button
 * @prop {Boolean}  [isReadOnly=false]              hides all action buttons when true
 * @prop {Boolean}  [isOwner=false]                 ensures full access if user is owner of an entity, regardless of other permissions
 * @prop {String}   [itemId='']                     used for permissions (user can perform actions on that item)
 *                                                    - can be .id, .canonical, .username .ref etc.
 * @prop {Function} [onDelete=()=>null]             handles delete button click, delete only shown when $isCreationRoute is false
 *                                                    - coupled with $toggle[path] in parent, so will call onDelete(true) when called
 *                                                    - e.g. :on-delete="$toggle.showDeleteModal" === $toggle.showDeleteModal(true)
 * @prop {Boolean}  [saveBtnText=forms.btnSave]     to override the save button text from "save"
 *
 * SLOTS
 * @slot details_form
 * @slot details_formFullWidth
 * @slot details_secondaryContent
 * @slot details_secondaryContentFullWidth
 * @slot details_actionBtns
 */

export default {
  name: 'CyDetails',
  props: {
    // Required
    canCancel: {
      type: Boolean,
      required: true,
    },
    canSave: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    // Optional
    deleteBtnText: {
      type: String,
      default: () => i18n.t('forms.btnDelete'),
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: [String, Number],
      default: '',
    },
    onDelete: {
      type: Function,
      default: () => null,
    },
    saveBtnText: {
      type: String,
      default: () => i18n.t('forms.btnSave'),
    },
  },
  computed: {
    hasPermissionTo () {
      return _.$get(this.$route.meta, 'actions', {})
    },
    canDelete () {
      return !this.hideDelete
    },
  },
  mounted () {
    if (_.has(this.$attrs, 'item-canonical')) console.warn('[CyDetails] item-canonical is not a valid prop, please provide item-id instead. Refer to component docs for more info.')
  },
  methods: {
    can () {
      const { itemId, isOwner, canDelete, $isCreationRoute, hasPermissionTo } = this
      const { canDisplay } = this.$cycloid.permissions
      const hasPermsToCreate = hasPermissionTo.create && canDisplay(hasPermissionTo.create)
      const hasPermsToDelete = hasPermissionTo.delete && canDisplay(hasPermissionTo.delete, itemId)
      const hasPermsToUpdate = hasPermissionTo.update && canDisplay(hasPermissionTo.update, itemId)

      return $isCreationRoute
        ? {
            delete: false,
            save: hasPermsToCreate,
          }
        : {
            delete: canDelete && _.some([isOwner, hasPermsToDelete]),
            save: isOwner || hasPermsToUpdate,
          }
    },
  },
}
</script>
